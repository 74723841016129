import styled from 'styled-components';
import { Divider, Input, Select } from 'antd';

export const CustomInput = styled(Input)`
	flex: auto;
	border-radius: 7px;
`;

export const CustomDivider = styled(Divider)`
	margin: 4px 0;
`;

export const CustomSelect = styled(Select)`
	background-color: #ffffff;

	.ant-select-selector {
		display: flex;
		font-size: 1rem;
		align-items: center;
		border: 1px solid #d9d9d9;
		height: 2.5rem !important;
		border-radius: 6px !important;

		span {
			display: flex;
			align-items: center;
		}

		&:hover {
			cursor: pointer;
		}
	}
`;
