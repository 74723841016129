import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { PlusCircleFilled } from '@ant-design/icons';

import { CreateButton } from './styles';
import { Title } from '@/components/Typography';

const NOT_APPLICABLE = 'N/A';

interface HeaderProps {
	title?: string;
	loading?: boolean;
	noTranslate?: boolean;
	onClick: () => void;
}

export function Header({ title = NOT_APPLICABLE, noTranslate = false, onClick, loading }: Readonly<HeaderProps>) {
	return (
		<Row align="middle">
			<Col span={12}>
				{loading && title === NOT_APPLICABLE ? (
					<Skeleton.Input active />
				) : (
					<Title level={3} noTranslate={noTranslate} style={{ marginBottom: 0 }}>
						{title}
					</Title>
				)}
			</Col>
			<Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<CreateButton type="primary" onClick={onClick} icon={<PlusCircleFilled />}>
					{I18n.get('New report')}
				</CreateButton>
			</Col>
		</Row>
	);
}
