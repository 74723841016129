import { Button } from 'antd';
import styled from 'styled-components';

export const CreateButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	border-radius: 5px;
	padding: 0.8rem 0.6rem;
	background-color: #2f54eb;

	span:last-child {
		font-size: 1rem;
		line-height: 22px;
	}

	.anticon {
		display: flex;
		font-size: 1.4rem;
		align-items: center;
	}
`;
